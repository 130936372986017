.sidebar {
    background: var(--dark-color);
    height: 100vh;
    max-width: 220px;
    width: 100%;
    padding: 1.2em 1.3em;
    overflow: scroll;
}
.sidebar header {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
}
.sidebar header::after {
    display: block;
    content: "";
    width: 50px;
    height: 2px;
    background-color: var(--aqua-color);
    margin: 0.5em auto;
}
.sidebar header h1 a {
    color: #fff;
    font-size: .8em;
}
.sidebar ul {
    display: flex;
    flex-direction: column;
    list-style-type: none;
    text-transform: uppercase;
}
.sidebar ul li {
    font-size: 1.1rem;
    border-bottom: 1px solid var(--primary-color);
    padding: 1.5em 0;
    color: #858585;
}
.sidebar ul li.active {
    color: #fff;
}
.sidebar ul li {
    display: flex;
    gap: .3em
}
.sidebar ul li .icon {
    display: flex;
    align-items: center;
}
@media screen and (max-width: 768px) {
    .sidebar {
        max-width: 70px;
        padding: 1.2em .7em;
    }
    .sidebar header {
        font-size: .35rem;
    }
    .sidebar header::after {
        width: 20px;
    }
    .sidebar ul li {
        display: flex;
        justify-content: center;
    }
    .sidebar ul li .text {
        display: none;
    }
}