.formContainer {
  background: #000;
  border-radius: 10px;
  padding: 1.5em;
  margin-bottom: 3em;
}
.formContainer header {
  margin-bottom: 2em;
  border-bottom: 1px solid #3f3f3f;
  padding-bottom: 1em;
}
.formContainer .formControl:not(:last-child) {
  margin-bottom: 1.5em;
}
.submitButton {
  width: 100%;
  background: var(--purple-color);
  transition: all 0.2s;
}
.submitButton:hover {
  background: var(--dark-purple-color);
}
.table {
  border-collapse: collapse;
}
button {
  color: #fff;
  outline: none;
  border: 0;
  margin: 0 .2em;
  padding: 1em 1.5em;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 2px;
}
button.aquaButton {
  background: var(--aqua-color);
}
button.deleteButton {
  background: red;
}
button.aquaButton:hover {
  background: var(--dark-aqua-color);
}
button.deleteButton:hover {
  background: rgb(197, 0, 0);
}
.addNew {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}
.addNew p {
  font-size: 2rem;
}
.addNew button {
  width: 100px;
}