.table {
  overflow: scroll;
  border-collapse: collapse;
  border-radius: 5px;
}
table thead {
  background: var(--purple-color);
}
table thead td {
  padding: 1em;
}
table, td {
  border: 1px solid;
}
table td {
  text-align: center;
  padding: .7em;
  font-size: 1.1rem;
}
table td img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}
button {
  color: #fff;
  outline: none;
  border: 0;
  margin: 0 .2em;
  padding: 1em 1.5em;
  text-align: center;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 2px;
}
button.aquaButton {
  background: var(--aqua-color);
}
button.blueButton {
  background: var(--blue-color);
}
button.blueButton:hover {
  background: var(--dark-blue-color);
}
button.deleteButton {
  background: red;
}
button.aquaButton:hover {
  background: var(--dark-aqua-color);
}
button.deleteButton:hover {
  background: rgb(197, 0, 0);
}
.addNew {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2em;
}
.addNew p {
  font-size: 2rem;
}
.addNew button {
  width: 100px;
}