.title {
  text-transform: uppercase;
}
.title::after {
  content: "";
  width: 93px;
  height: 5px;
  background: var(--purple-color);
  display: block;
  border-radius: 30px;
  margin: .4em 0;
}
.subTitle {
  margin: 2em 0 1em
}
ol {
  margin-left: 1em;
}