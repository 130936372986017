.title h2 {
  color: #fff;
  font-size: 2.5rem;
  margin-bottom: .2em;
}
.title p {
  color: #959595;
  font-size: 1.1rem;
  margin-bottom: 1em;
}
.title::after {
  content: "";
  width: clamp(120px, 20%, 200px);
  height: 5px;
  background: var(--purple-color);
  display: block;
  margin-top: 0.2em;
  border-radius: 30px;
  margin-bottom: .5em;
}
.playlistContainerContent {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2em;
  margin-bottom: 3em;
}
.topPart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1em;
}
.saveButton {
  background: var(--aqua-color);
}
.saveButton:hover {
  background: var(--dark-aqua-color);
}