.playlistCard {
  border-radius: 5px;
  background: var(--dark-color);
  padding: 1.5em;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  transition: all 0.2s ease-in-out;
  aspect-ratio: 0.97;
}
.playlistCard img {
  width: 100%;
  height: 210px;
  object-fit: cover;
}
.playlistCard .text {
  margin-top: .5em;
}
.playlistCard .text .name {
  font-size: 2rem;
  margin-bottom: .3em;
}
.playlistCard .text .plays {
  display: flex;
  align-items: center;
  gap: .3em;
  color: var(--muted-text);
}
.playlistCard .text .playsContainerIcon {
  display: flex;
  align-items: center;
}
.assigningButton {
  background: var(--aqua-color);
  width: 100%;
  margin-top: 1em;
}
.assigningButton:hover, .assigningButton.assigned {
  background: var(--dark-aqua-color);
}