:root {
  --primary-color: #1E1E1E;
  --dark-color: #121212;
  --aqua-color: #34b5ac;
  --purple-color: #5a2d6f;
  --dark-purple-color: #48205b;
  --dark-aqua-color: #268a83;
  --blue-color: rgb(2, 2, 170);
  --dark-blue-color: rgb(7, 7, 150);
  --muted-text: #d4d4d4;
  --gray-color: #2d2d2d;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--primary-color);
  color: #fff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a {
  text-decoration: none;
}